export enum AppDomain {
  Hookupers = 'hookupers.com',
  BestHookuper = 'besthookuper.com',
  HookupParadise = 'hookupparadise.com',
  DatesMe = 'dates-me.com',
  MePlusYou = 'meplusyou.club',
  SimpleDatesMe = 'simpledates.me',
  LocalsDate = 'locals-date.com',
  LustStation = 'lust-station.com',
  Localsjoy = 'localsjoy.com',

  // TODO add site types
  // * MIDS
  Meamo = 'meamo.club',
  Matchess = 'matchess.club',
  Uniteus = 'uniteus.club',
  Ppl2ppl = 'ppl2ppl.club',
  Theunite = 'theunite.club',
  DreamsDate = 'dreams-date.com',
  NeverAlones = 'neveralones.com',
  DateaSoul = 'dateasoul.com',
  DateaSoulmate = 'dateasoulmate.com',
  YourLove4Life = 'yourlove4life.com',
  Tiamo = 'ti-amos.com',
  SweatHeartSoul = 'sweatheartsoul.com',
  RomanticWay = 'romantic-way.com',
  AdventuroUsDate = 'adventurousdate.com',
  TheJoyDate = 'thejoydate.com',
  LovePath = 'love-path.com',
  GetParamour = 'getparamour.com',
  TripToLove = 'trip-tolove.com',

  FinderSingles = 'finder-singles.com',
  JoyFinder = 'joy-finder.com',
  FlirtGuru = 'flirt-guru.com',
  FlirtDiscovery = 'flirt-discovery.com',
  AdventureRelation = 'adventure-relation.com',

  SimpleDatesCo = 'simpledates.co',

  RelationshipNest = 'relationshipnest.com',
  CupidKeys = 'cupidkeys.com',
  AmourPulse = 'amourpulse.com',
  MyConnectly = 'myconnectly.com',
  TenderTalkers = 'tendertalkers.com',
  SupremeDate = 'supreme-date.com',
  TheCupies = 'thecupies.com',
  LookLie = 'looklie.com',
  PerfectDatings = 'perfect-datings.com',
  DatingForLife = 'dating-forlife.com',
  ForeverLover = 'forever-lover.com',
  FlirtingLover = 'flirting-lover.com',
  DatingsLover = 'datings-lover.com',
  BeValentine = 'be-valentine.com',
  OnesValentine = 'onesvalentine.com',
  DayDreamme = 'daydreamme.com',
  DayDreamsDate = 'daydreamsdate.com',
  Delightedus = 'delightedus.com',
  BestForFlirt = 'bestforflirt.com',
  PerfectFlirt = 'perfect-flirt.com',
  MeetLie = 'meetlie.net',
  KlubMe = 'klubme.net',
  Ppls = 'ppls.club',
  Hitchii = 'hitchii.com',
  SociableSpots = 'sociablespots.com',
  MeetNests = 'meetnests.com',
  PeopleMeetClub = 'peoplemeetclub.com',
  GotLinkedNow = 'gotlinkednow.com',
  MeetHarbor = 'meetharbor.com',
  RelationTunes = 'relationtunes.com',
  DateMateHub = 'datematehub.com',
  LinkupNook = 'linkupnook.com',
  TheLinkupers = 'thelinkupers.com',
  SoulMatesLab = 'soulmateslab.com',
  FairyDates = 'fairydates.com',
  WaitForMe = 'wait-forme.com',
  LookFor4you = 'lookfor4you.com',
  TheManWoman = 'themanwoman.com',
  UsTogether = 'us-together.com',

  // * DESCRIPTORS DOMAINS START
  PaysPlay = 'paysplay.com',
  Bst4flPay = 'bst4flpay.com',
  ArmBills = 'armbills.com',
  Armor2Pay = 'armor2pay.com',
  Bill4Info = 'bill4info.com',
  BillingAct = 'billingact.com',
  BillNow24 = 'billnow24.com',
  Charge2Rock = 'charge2rock.com',
  ChecksPay = 'checkspay.com',
  HelpInBill = 'helpinbill.com',
  Hub4Bill = 'hub4bill.com',
  Paid4Act = 'paid4act.com',
  PaiFastly = 'paifastly.com',
  Pay2Connect = 'pay2connect.com',
  PayInSec = 'payinsec.com',
  Payment2Rock = 'payment2rock.com',
  FlrtPay = 'flrtpay.com',
  BillTime24 = 'billtime24.com',
  Debit2Pay = 'debit2pay.com',
  Info_Pay24 = 'info-pay24.com',
  InfoPay24 = 'infopay24.com',
  PaymentInfo24 = 'payment-info24.com',
  BillInfo24 = 'bill-info24.com',
  ChPay24 = 'ch-pay24.com',
  BestBill = 'best-bill.com',
  BestBill24 = 'best-bill24.com',
  BestPay24 = 'best-pay24.com',
  DrmsPay = 'drms-pay.com',
  BvlntnPay = 'bvlntn-pay.com',
  PplPay = 'ppl-pay.com',
  DtngPay = 'dtng-pay.com',
  LklBill = 'lkl-bill.com',
  BstfrflrtPay = 'bstfrflrt-pay.com',
  JfndrPay = 'jfndr-pay.com',
  LvpthPay = 'lvpth-pay.com',
  TndPay = 'tnd-pay.com',
  APay24 = 'a-pay24.com',
  NstPay24 = 'nstpay24.com',
  ConnectPay24 = 'connectpay24.com',
  // * DESCRIPTORS DOMAINS END
}
